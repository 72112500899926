import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'
import Nav from '../../components/Nav'

const HopeDetectiveGirl = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const hdg01 = '../../assets/images/hdg/hdg_1_main.jpg'
  const hdg02 = '../../assets/images/hdg/hdg_2_main.jpg'

  return (
    <Layout>
      <Helmet title="希望探偵少女 (小説)" />
      <HeaderGeneric />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="content" className="main special">
          <header className="major">
            <h2>希望探偵少女 (小説)</h2>
          </header>
          <ul className="features2">
            <li>
              <span className="image major"><StaticImage src={hdg01} alt="" /></span>
            </li>
            <li>
              <span className="image major"><StaticImage src={hdg02} alt="" /></span>
            </li>
          </ul>
          <footer className="major">
            <p>脱出推理サスペンスゲーム、探偵少女シリーズの完結編小説</p>
            <p><h6><strong>※この物語はゲーム『脱出探偵少女』『死神探偵少女』および、追加シナリオ『家出探偵少年』『はじまりの探偵少女』のネタバレを含みます。ご注意ください。</strong></h6></p>
          </footer>
        </section>
        <section id="hdg_1" className="main">
          <header className="major">
            <h2>希望探偵少女 上巻</h2>
          </header>
          <p>賢者倶楽部による同時ジャックテロによって、変わり果ててしまった日本。そんな中、ある一つのメッセージをきっかけに、かつてのデスゲームの生き残り達が山吹希のもとに集う。 最悪の状況を打破し、探し求めていた少女を救うため、彼らは賢者倶楽部に立ち向かう。</p>
          <table>
            <colgroup><col span="1" /></colgroup>
            <tbody>
              <tr><th>タイトル</th><td>希望探偵少女 上巻</td></tr>
              <tr><th>提供媒体</th><td>小説 / 文庫 / PDF　※製本版は限定生産</td></tr>
              <tr><th>ページ数</th><td>120p</td></tr>
              <tr><th>ジャンル</th><td>ミステリー・サスペンス</td></tr>
              <tr><th>発行日</th><td>2019年 12月 31日</td></tr>
              <tr><th>購入ページ</th>
              <td><a href="https://cretia-studio.booth.pm/items/1742914" target="_blank" rel="noopener noreferrer" className="icon"><img src="https://asset.booth.pm/static-images/banner/200x40_01.png" alt="" /></a></td></tr>
            </tbody>
          </table>
        </section>
        <section id="hdg_2" className="main">
          <header className="major">
            <h2>希望探偵少女 下巻</h2>
          </header>
          <p>目を覚ました山吹に突きつけられたのは『絶望』。それでも賢者倶楽部の真相へと近づく彼女と、峰岸望を救おうと奮闘する生徒たち。黒幕から語られる全ての真相を知った時、彼女たちが下した最後の決断とは。</p>
          <table>
            <colgroup><col span="1" /></colgroup>
            <tbody>
              <tr><th>タイトル</th><td>希望探偵少女 下巻</td></tr>
              <tr><th>提供媒体</th><td>小説 / 文庫 / PDF　※製本版は限定生産</td></tr>
              <tr><th>ページ数</th><td>190p</td></tr>
              <tr><th>ジャンル</th><td>ミステリー・サスペンス</td></tr>
              <tr><th>発行日</th><td>2020年 12月 31日</td></tr>
              <tr><th>購入ページ</th>
              <td><a href="https://cretia-studio.booth.pm/items/2599770" target="_blank" rel="noopener noreferrer" className="icon"><img src="https://asset.booth.pm/static-images/banner/200x40_01.png" alt="" /></a></td></tr>
            </tbody>
          </table>
        </section>
      </div>
    </Layout>
  )
}

export default HopeDetectiveGirl
